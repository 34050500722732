import { array, lazy, object, string } from 'yup';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const signInValidationSchema = (t) => object().shape({
	username: string().required(t('ERRORS.EMPTY_USERNAME')),
	password: string()
		.required(t('ERRORS.EMPTY_PASSWORD'))
});

export const forgotPasswordValidationSchema = (t) => object().shape({
	username: string().required(t('ERRORS.EMPTY_USERNAME')),
});
export const resetPasswordValidationSchema = (t) => object().shape({
	password: string().required(t('RESETPASSWORD.ERRORS.PASSWORD_REQUIRED'))
		.matches(/^.*[0-9].*/, t('ERRORS.ATLEAST_ONE_NUMBER'))
		.matches(/[-!$%^&*()_+|~=`{}[:;<>?,.@#\]]/g, t('ERRORS.ATLEAST_ONE_SYMBOL'))
		.matches(/.*[A-Z].*/, t('ERRORS.ATLEAST_ONE_UPPER_CHARACTER'))
		.matches(/.*[a-z].*/, t('ERRORS.ATLEAST_ONE_LOWER_CHARACTER'))
		.min(8, t('ERRORS.INVALID_PASSWORD')),
	password_confirmation: string().required(t('RESETPASSWORD.ERRORS.PASSWORDCONFIRM_REQUIRED'))
});
export const registerConsultantValidationSchema = (t) => object().shape({
	first_name: string().required(t('USERADD.ERRORS.FIRSTNAME_REQUIRED')).max(20),
	last_name: string().required(t('USERADD.ERRORS.LASTNAME_REQUIRED')).max(20),
	mobile_number: string()
		.required(t('USERADD.ERRORS.MOBILE_REQUIRED'))
		.matches(phoneRegExp, t('USERADD.ERRORS.INVALID_NUMBER'))
		.min(10, 'to short')
		.max(10, 'to long'),
	username: string().required(t('USERADD.ERRORS.USERNAME_REQUIRED')).max(20),
	password: string().required(t('USERADD.ERRORS.PASSWORD_REQUIRED'))
		.matches(/^.*[0-9].*/, t('ERRORS.ATLEAST_ONE_NUMBER'))
		.matches(/[-!$%^&*()_+|~=`{}[:;<>?,.@#\]]/g, t('ERRORS.ATLEAST_ONE_SYMBOL'))
		.matches(/.*[A-Z].*/, t('ERRORS.ATLEAST_ONE_UPPER_CHARACTER'))
		.matches(/.*[a-z].*/, t('ERRORS.ATLEAST_ONE_LOWER_CHARACTER'))
		.min(8, t('ERRORS.INVALID_PASSWORD')),
	password_confirmation: string().required(t('USERADD.ERRORS.PASSWORDCONFIRM_REQUIRED'))
});
export const inviteConsultantValidationSchema = (t) => object().shape({
	email: string().required(t('ERRORS.EMPTY_EMAIL')).email(t('ERRORS.INVALID_EMAIL')),
});
export const chartTableValidationSchema = (t, length) => {
	return object().shape({
		value: lazy(val => (Array.isArray(val) ? array().min(length, t('ERRORS.SELECT_OPTION')).of(string().required(t('ERRORS.SELECT_OPTION')).trim()) : string().required(t('ERRORS.EMPTY_VALUE'))))
		/* value: mixed()
			.when('isArray', {
				is: Array.isArray,
				then: array().min(length).of(string().required(t('ERRORS.SELECT_OPTION'))),
				otherwise: string().required(t('ERRORS.EMPTY_VALUE')),
			}) */
	});
};
export const addUserValidationSchema = (t) => object().shape({
	first_name: string().required(t('USERADD.ERRORS.FIRSTNAME_REQUIRED')).max(20),
	last_name: string().required(t('USERADD.ERRORS.LASTNAME_REQUIRED')).max(20),
	mobile_number: string()
		.required(t('USERADD.ERRORS.MOBILE_REQUIRED'))
		.matches(phoneRegExp, t('USERADD.ERRORS.INVALID_NUMBER'))
		.min(10, 'to short')
		.max(10, 'to long'),
	email: string().required(t('USERADD.ERRORS.EMAIL_REQUIRED')).email(t('ERRORS.INVALID_EMAIL')),
	user_role: string().required(t('USERADD.ERRORS.USERROLE_REQUIRED')),
	status: string().required(t('USERADD.ERRORS.USERSTATUS_REQUIRED')),
	username: string().required(t('USERADD.ERRORS.USERNAME_REQUIRED')).max(20),
	password: string().required(t('USERADD.ERRORS.PASSWORD_REQUIRED'))
		.matches(/^.*[0-9].*/, t('ERRORS.ATLEAST_ONE_NUMBER'))
		.matches(/[-!$%^&*()_+|~=`{}[:;<>?,.@#\]]/g, t('ERRORS.ATLEAST_ONE_SYMBOL'))
		.matches(/.*[A-Z].*/, t('ERRORS.ATLEAST_ONE_UPPER_CHARACTER'))
		.matches(/.*[a-z].*/, t('ERRORS.ATLEAST_ONE_LOWER_CHARACTER'))
		.min(8, t('ERRORS.INVALID_PASSWORD')),
	password_confirmation: string().required(t('USERADD.ERRORS.PASSWORDCONFIRM_REQUIRED'))
});

export const editUserValidationSchema = (t) => object().shape({
	first_name: string().required(t('USEREDIT.ERRORS.FIRSTNAME_REQUIRED')).max(20),
	last_name: string().required(t('USEREDIT.ERRORS.LASTNAME_REQUIRED')).max(20),
	mobile_number: string()
		.required(t('USEREDIT.ERRORS.MOBILE_REQUIRED'))
		.matches(phoneRegExp, t('USEREDIT.ERRORS.INVALID_NUMBER'))
		.min(10, 'to short')
		.max(10, 'to long'),
	email: string().required(t('USEREDIT.ERRORS.EMAIL_REQUIRED')).email(t('USEREDIT.ERRORS.INVALID_EMAIL')),
	user_role: string().required(t('USEREDIT.ERRORS.USERROLE_REQUIRED')),
	status: string().required(t('USEREDIT.ERRORS.USERSTATUS_REQUIRED')),
	username: string().required(t('USEREDIT.ERRORS.USERNAME_REQUIRED')).max(20),
});

export const editProfileValidationSchema = (t) => object().shape({
	first_name: string().required(t('USEREDIT.ERRORS.FIRSTNAME_REQUIRED')).max(20),
	last_name: string().required(t('USEREDIT.ERRORS.LASTNAME_REQUIRED')).max(20),
});

export const changeBackupValidationSchema = (t) => object().shape({
	type: string().required(t('BACKUP.ERRORS.TYPE')).max(20),
});

export const changeBedTypeValidationSchema = (t) => object().shape({
	bed_type: string().required(t('BEDTYPES.ERRORS.BEDTYPE')).max(20),
});

export const changeHospitalConfigValidationSchema = (t) => object().shape({
	registration_prefix: string().nullable().length(4, t('HOSPITALCONFIG.ERRORS.REGISTRATIONPREFIX')),
	patient_prefix: string().nullable().length(4, t('HOSPITALCONFIG.ERRORS.PATIENTPREFIX')),
});

export const changePasswordValidationSchema = (t) => object().shape({
	oldpassword: string().required(t('CHANGEPASSWORD.ERRORS.CURRENTPASSWORD')),
	password: string().required(t('CHANGEPASSWORD.ERRORS.PASSWORD_REQUIRED'))
		.matches(/^.*[0-9].*/, t('ERRORS.ATLEAST_ONE_NUMBER'))
		.matches(/[-!$%^&*()_+|~=`{}[:;<>?,.@#\]]/g, t('ERRORS.ATLEAST_ONE_SYMBOL'))
		.matches(/.*[A-Z].*/, t('ERRORS.ATLEAST_ONE_UPPER_CHARACTER'))
		.matches(/.*[a-z].*/, t('ERRORS.ATLEAST_ONE_LOWER_CHARACTER'))
		.min(8, t('ERRORS.INVALID_PASSWORD')),
	password_confirmation: string().required(t('CHANGEPASSWORD.ERRORS.PASSWORDCONFIRM_REQUIRED'))
});


export const admitPatientValidationSchema = (t) => object().shape({
	date_of_admission: string().required(t('PATIENTS.VALIDATIONS.DATE_OF_ADMINSSION')),
	accompanied_by: string().required(t('PATIENTS.VALIDATIONS.ACCOMPANIED_BY')).max(20),
	relationship: string().required(t('PATIENTS.VALIDATIONS.RELATIONSHIP')).max(20),
	companion_mobile_no: string()
		.required(t('PATIENTS.VALIDATIONS.MOBILE_REQUIRED'))
		.matches(phoneRegExp, t('PATIENTS.VALIDATIONS.INVALID_NUMBER'))
		.min(10, 'to short')
		.max(10, 'to long'),
	alternate_contact_no: string()
		.matches(phoneRegExp, t('PATIENTS.VALIDATIONS.INVALID_NUMBER'))
		.min(10, 'to short')
		.max(10, 'to long'),
});