import React, { useEffect, useRef } from 'react';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Alert, Button, Card, Form, Row, Stack } from 'react-bootstrap';
import Loader from '../../components/SplashScreen/loader';
import { getHospitalPrefix, updateHospitalPrefix } from '../../redux/hospital';
import { resetHospitalPrefixConfigComplete } from '../../redux/hospital/slice';
import { changeHospitalConfigValidationSchema } from '../../utils/validation';

const HospitalConfig = () => {
	const { currentHospital } = useSelector(state => state.auth);
	const { Loading, hospitalConfigPrefix, hospitalConfigPrefixError, hospitalConfigPrefixUpdate, hospitalConfigPrefixUpdateError } = useSelector(state => state.hospital);
	const validationMessageRef = useRef();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		return () => {
			dispatch(resetHospitalPrefixConfigComplete());
		};
	}, []);

	useEffect(() => {
		if (!isEmpty(hospitalConfigPrefixUpdateError) || !isEmpty(hospitalConfigPrefixError) || !isEmpty(hospitalConfigPrefixUpdate?.message)) {
			validationMessageRef.current?.scrollIntoView();
		}
	}, [hospitalConfigPrefixUpdateError, hospitalConfigPrefixUpdate, hospitalConfigPrefixError]);

	useEffect(() => {
		if (!isEmpty(currentHospital)) {
			dispatch(getHospitalPrefix({ hospital_id: currentHospital.value }));
		}
	}, [currentHospital]);

	const backToDashboard = () => {
		navigate('/');
	};

	const handleChangeBackupSubmit = (values) => {
		const payload = {
			'hospital_id': currentHospital.value,
			...values
		};
		dispatch(updateHospitalPrefix(payload));
	};
	return (
		<Card>
			<Card.Header className='bg-white fw-bold'>
				{t('HOSPITALCONFIG.TITLE')}
			</Card.Header>
			<Card.Body>
				{hospitalConfigPrefixUpdateError &&
					<Alert variant="danger" ref={validationMessageRef}>
						<ul className='mb-0'>
							{hospitalConfigPrefixUpdateError.split('|').map((error, index) => (
								<li key={index}>{error}</li>
							))}
						</ul>
					</Alert>}
				{hospitalConfigPrefixError &&
					<Alert variant="danger" ref={validationMessageRef}>
						<ul className='mb-0'>
							{hospitalConfigPrefixError.split('|').map((error, index) => (
								<li key={index}>{error}</li>
							))}
						</ul>
					</Alert>}
				{hospitalConfigPrefixUpdate?.message &&
					<Alert variant="success" ref={validationMessageRef}>
						{hospitalConfigPrefixUpdate?.message}
					</Alert>}
				{Loading &&
					<Row className='d-flex justify-content-center mt-4'>
						<Loader />
					</Row>}
				{!isEmpty(hospitalConfigPrefix) && <Formik initialValues={hospitalConfigPrefix} validationSchema={changeHospitalConfigValidationSchema(t)} onSubmit={handleChangeBackupSubmit}
				>
					{({ errors, values, handleChange, handleBlur, handleSubmit }) => (
						<Form noValidate onSubmit={handleSubmit}>
							<Form.Group className='mb-3' controlId='formGridAppKey'>
								<Form.Label>{t('HOSPITALCONFIG.FIELDS.REGISTRATIONPREFIX')}</Form.Label>
								<Form.Control
									type='text'
									placeholder={t('HOSPITALCONFIG.PLACEHOLDER.REGISTRATIONPREFIX')}
									name='registration_prefix'
									value={values.registration_prefix}
									onChange={handleChange}
									onBlur={handleBlur}
									isInvalid={errors.registration_prefix} />
								<Form.Control.Feedback type='invalid'>
									{errors.registration_prefix}
								</Form.Control.Feedback>
							</Form.Group>

							<Form.Group className='mb-3' controlId='formGridAppSecret'>
								<Form.Label>{t('HOSPITALCONFIG.FIELDS.PATIENTPREFIX')}</Form.Label>
								<Form.Control
									type='text'
									placeholder={t('HOSPITALCONFIG.PLACEHOLDER.PATIENTPREFIX')}
									name='patient_prefix'
									value={values.patient_prefix}
									onChange={handleChange}
									onBlur={handleBlur}
									isInvalid={errors.patient_prefix} />
								<Form.Control.Feedback type='invalid'>
									{errors.patient_prefix}
								</Form.Control.Feedback>
							</Form.Group>

							<Stack direction='horizontal' gap={2}>
								<Button title={t('GENERAL.UPDATE')} disabled={Loading} variant="primary" type="submit">{t('GENERAL.UPDATE')}</Button>
								<Button title={t('GENERAL.CANCEL')} variant="danger" onClick={backToDashboard}>{t('GENERAL.CANCEL')}</Button>
							</Stack>
						</Form>
					)}
				</Formik>}
			</Card.Body>
		</Card>
	);
};

export default HospitalConfig;
