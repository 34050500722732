import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import authReducer from './auth/slice';
import userReducer from './users/slice';
import patientReducer from './patients/slice';
import formReducer from './forms/slice';
import hospitalReducer from './hospital/slice';
import dischargeClinicalReducer from './discharge-clinical/slice';
import archivedPatientReducer from './archived-patients/slice';
import monitoringReducer from './monitoring/slice';
import hospitalMessagesReducer from './chat/slice';
const reducers = combineReducers({
	auth: authReducer,
	users: userReducer,
	patients: patientReducer,
	forms: formReducer,
	hospital: hospitalReducer,
	dischargeClinical: dischargeClinicalReducer,
	archivedPatient: archivedPatientReducer,
	monitoring: monitoringReducer,
	hospitalMessages: hospitalMessagesReducer,
});
const rootReducer = (state, action) => reducers(state, action);
const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['']
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});
export const persistor = persistStore(store);